import React, { useState, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { TextInput, DatePicker, Check } from "../components/ui"
import { usePostalJp } from "use-postal-jp"
import Data from "./Store.json"

const Form = () => {
  const methods = useFormContext()
  const [value, setValue] = useState("")
  const [pack, setPack] = useState()
  const [price, setPrice] = useState()
  const [sum, setSum] = useState()

  const {
    address,
    error,
    pending,
    sanitizedCode,
    setPostalCode,
  } = usePostalJp()

  const Store = Data.data
  useEffect(() => {
    Store.map((d) => {
      if (document.URL.match(d.id)) {
        methods.setValue("store", d.store)
        methods.setValue("tel", d.tel)
        methods.setValue("sendmail", d.mail)
        methods.setValue("address", d.address)
        {
          d.pack ? setPack(d.pack) : setPack(50)
        }
        {
          d.price ? setPrice(d.price) : setPrice(500)
        }
      }
    })
    setPostalCode(value)
  }, [value, setPostalCode])
  useEffect(() => {
    let d = methods.getValues("set")
    if (d > 0) {
      methods.setValue("setprice", d * price)
      setSum(methods.getValues("setprice"))
    } else {
      methods.setValue("setprice", 0)
      setSum(methods.getValues("setprice"))
    }
  })

  const Plan = methods.watch("plan")
  const ChangeZip = (e: any) => {
    setValue(e)
    methods.setValue("pref", address.prefecture, {
      shouldDirty: true,
      shouldValidate: true,
    })
    {
      address.address1 &&
        methods.setValue("city", address.address1 + address.address2, {
          shouldDirty: true,
          shouldValidate: true,
        })
    }
  }

  return (
    <>
      <input type="hidden" name="store" ref={methods.register} />
      <input type="hidden" name="tel" ref={methods.register} />
      <input type="hidden" name="sendmail" ref={methods.register} />
      <input type="hidden" name="address" ref={methods.register} />
      <input type="hidden" name="setprice" ref={methods.register} />
      <div className="w-full shadow-2xl p-8" id="form1" key="form1">
        <div className="flex flex-col -mx-3 mb-6">
          {/* <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2 "
              htmlFor="plan"
            >
              お申込内容
              <span
                className={`ml-1 ${
                  Plan && !methods.errors.plan
                    ? "text-green-500 border border-green-500"
                    : "text-red-500 border border-red-500"
                }`}
              >
                {Plan && !methods.errors.plan ? "OK" : "必須"}
              </span>
              <div className="flex flex-col sm:flex-row">
                {Plans.map((d) => (
                  <div key={d.id}>
                    <input
                      type="radio"
                      name="plan"
                      value={d.value}
                      className="m-2 form-radio h-5 w-5 text-indigo-600 align-middle "
                      ref={methods.register}
                    />
                    <span
                      className="align-middle text-gray-700"
                      onClick={() => methods.setValue("plan", d.value)}
                    >
                      {d.value} {d.price}
                    </span>
                  </div>
                ))}
              </div>
            </label>
            <p className="text-red-500 text-xs italic">
              {methods.errors.plan?.message}
            </p>
          </div> */}

          <div className="flex flex-col">
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="ご希望セット数"
                type="number"
                name="set"
                auto="set"
                required={true}
                min={0}
                footer={methods.errors.set?.message}
              />

              <p className="text-gray-600 text-xs italic text-right">
                ※１セット{pack ? pack : 50}枚入り￥{price ? price : 500}
                となっております
              </p>
              <p className="text-right">合計金額：￥{sum}</p>
              <br />
              <TextInput
                label="お名前・会社名(部署名)"
                type="text"
                name="name1"
                auto="name"
                required={true}
                footer={methods.errors.name1?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="担当者様のお名前(任意)"
                type="text"
                name="name2"
                auto="name"
                required={false}
                footer={methods.errors.name2?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row -mx-3 mb-6">
          <div className="w-full sm:w-1/4 text-center mb-6">ご住所</div>
          <div className="w-full flex flex-col">
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="郵便番号(住所自動入力)"
                type="text"
                name="zip"
                auto="postal-code"
                required={true}
                onBlur={(e: any) => ChangeZip(e.target.value)}
                onChange={(e: any) => ChangeZip(e.target.value)}
                footer={methods.errors.zip?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="都道府県"
                type="text"
                name="pref"
                auto="address-level1"
                required={true}
                footer={methods.errors.pref?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="市区町村"
                type="text"
                name="city"
                auto="address-level2"
                required={true}
                footer={methods.errors.city?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="番地"
                type="text"
                name="addr"
                auto="address-level3"
                place="例）１−１"
                required={true}
                footer={methods.errors.addr?.message}
              />
            </div>

            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="建物名・部屋番号"
                type="text"
                name="building"
                auto="address-level4"
                place="例）日経ビル　１０１"
                required={false}
                footer={methods.errors.building?.message}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col -mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="メールアドレス"
                type="email"
                name="email"
                auto="email"
                required={true}
                footer={methods.errors.email?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="お電話番号"
                type="tel"
                name="telephone"
                auto="tel"
                required={true}
                footer={methods.errors.telephone?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <TextInput
              label="連絡事項（任意）"
              type="textarea"
              name="message"
              required={false}
              footer={methods.errors.message?.message}
            />

            <p className="text-gray-600 text-xs italic">
              ご不明点やご要望がございましたらご入力ください
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form
